import React from 'react';
import './App.scss';
import './Components/Sidebar/Sidebar';
import Sidebar from './Components/Sidebar/Sidebar';
import Visualizer from './Components/Visualizer/Visualizer';

function App() {
  
  const[algorithm, setAlgorithm] = React.useState('BUBBLE SORT');
  const[columns, setColumns] = React.useState(30);
  const[running, setRunning] = React.useState(false);
  const[time, setTime] = React.useState(5);
  const[values, setValues] = React.useState<number[]>([]);
  const[selectedMain, setSelectedMain] = React.useState(-1);
  const[selectedSecondary, setSelectedSecondary] = React.useState(-1);
  const[selectedTernary, setSelectedTernary] = React.useState(-1);
  const[comparisons, setComparisons] = React.useState(0);
  const[arrayAccess, setArrayAccess] = React.useState(0);
  const width = window.innerWidth;

  return (
      <div className="App">
        {
          width > 768 &&
          <Sidebar running={running} setRunning={setRunning} 
          comparisons={comparisons} arrayAccess={arrayAccess} 
          setComparisons={setComparisons} setArrayAccess={setArrayAccess} 
          algorithm={algorithm} changeAlgorithm={setAlgorithm} time={time} 
          setTime={setTime} columns={columns} setColumns={setColumns} 
          values={values} setValues={setValues} setSelectedMain={setSelectedMain} 
          setSelectedTernary={setSelectedTernary} setSelectedSecondary={setSelectedSecondary}/>
        }
        {
          width > 768 &&
          <Visualizer running={running} 
          comparisons={comparisons} setComparisons={setComparisons} 
          arrayAccess={arrayAccess} setArrayAccess={setArrayAccess} 
          selectedMain={selectedMain} selectedSecondary={selectedSecondary} 
          selectedTernary={selectedTernary} values={values} setValues={setValues} 
          algorithm={algorithm} columns={columns} time={time}/>
        }
        {
          width <= 768 &&
          <div style={{textAlign: 'center', display:'flex', justifyContent: 'center', height: '100%', width: '100%'}}>
            <span style={{color:'white',fontSize:'1.5em' ,fontWeight:900, alignSelf: 'center', justifySelf:'center'}}>
              This application is only implemented to be used in a computer.
              Implementation for mobile is still work in progress.
            </span>
          </div>
        }
      </div>
  );
}

export default App;
