import * as React from 'react';
import './Tag.scss';

export default function Tag(props:any){
    return(
        <div className={"tag-wrapper " + ((props.selected)? "active" : "")}>
            <div className="tag-name">
                {props.name}
            </div>
            <div className="tag-selectioncolor">
            </div>
        </div>
    )
}