import * as React from 'react';
import './Visualizer.scss';
import Column from './Column/Column';

export default function Visualizer(props: any) {

    const[force, setForce] = React.useState(false);

    React.useEffect(()=>{
        props.values.length = 0;
        for(let i = 0; i < props.columns; i++){
            props.values.push(Math.floor(Math.random()*101)+10);
        }
        props.setValues(props.values);
        props.setArrayAccess(0);
        props.setComparisons(0);
        setForce(!force);
    },[props.columns, props.algorithm]);

    return (
        <div className="visualizer-wrapper">
            <div className="visualizer-info">
                <div>
                    <span>Comparisons: {props.comparisons}</span>
                </div>
                <div>
                    <span>Array accesses: {props.arrayAccess}</span>
                </div>
            </div>
            <div className="visualizer-columns">
                {
                    props.values.map((item:any, i:any)=>
                        <Column height={item} index={i} main={props.selectedMain} secondary={props.selectedSecondary} ternary={props.selectedTernary} key={i}/>
                    )
                }
            </div>
        </div>
    )
}