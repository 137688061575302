import * as React from 'react';
import './Selector.scss';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import TimerIcon from '@material-ui/icons/Timer';
import { copyFile } from 'fs';

export default function Selector(props: any) {

    const [force, forceUpdate] = React.useState(true);
    let arrayAccess = props.arrayAccess;
    let comparisons = props.comparisons;
    let setArrayAccess = props.setArrayAccess;
    let setComparisons = props.setComparisons;

    function updateTime(e: any) {
        props.setTime(e.target.value);
    }

    function updateCol(e: any) {
        props.setColumns(e.target.value);
    }

    function delay(n: any) {
        n = n || 2000;
        return new Promise(done => {
            setTimeout(() => {
                done();
            }, n);
        });
    }

    //Quicksort algorithm
    async function quicksort(array: any[], left: any, right: any) {
        let index = 0;

        await setComparisons(++comparisons);
        if (array.length > 1) {

            index = await partition(array, left, right);
            
            await setComparisons(++comparisons);
            if (left < index - 1) {
                await delay(props.time);
                await quicksort(array, left, index - 1);
            }

            await setComparisons(++comparisons);
            if (index < right) {
                await delay(props.time);
                await quicksort(array, index, right);
            }
        }
    }

    async function partition(items: any[], left: any, right: any) {
        setArrayAccess(++arrayAccess);
        var pivot = items[Math.floor((right + left) / 2)], //middle element
            i = left, //left pointer
            j = right; //right pointer

        props.setSelectedMain(Math.floor((right + left) / 2));
        props.setSelectedSecondary(i);
        props.setSelectedTernary(j);

        while (i <= j) {

            await delay(props.time);
            await setComparisons(++comparisons);
            await setArrayAccess(++arrayAccess);

            while (items[i] < pivot) {
                await delay(props.time);
                await setArrayAccess(++arrayAccess);
                await setComparisons(++comparisons);
                i++;
                await props.setSelectedSecondary(i);
            }
            await setComparisons(++comparisons);

            while (items[j] > pivot) {
                await delay(props.time);
                await setArrayAccess(++arrayAccess);
                await setComparisons(++comparisons);
                j--;
                await props.setSelectedTernary(j);
            }
            await setArrayAccess(++arrayAccess);
            await setComparisons(++comparisons);

            if (i <= j) {
                await delay(props.time);
                await swap(items, i, j); //swap two elements
                i++;
                j--;
                await props.setSelectedSecondary(j);
                await props.setSelectedTernary(i);
            }
            await setComparisons(++comparisons);
        }
        await setComparisons(++comparisons);

        await forceUpdate(!force);
        return i;
    }
    ////////////////////////////////////////////////////////////
    //Merge Sort Algorithm
    async function mergeSort(array: any[], l: any, r: any) {
        await setComparisons(++comparisons);
        if (l < r) {
            let m = Math.floor((l + r) / 2);

            await delay(props.time);
            await mergeSort(array, l, m);

            await delay(props.time);
            await mergeSort(array, m + 1, r);

            await delay(props.time);
            await merge(array, l, m, r);
        }
    }

    async function merge(array: any[], l: any, m: any, r: any) {
        let n1 = m - l + 1;
        let n2 = r - m;

        await props.setSelectedSecondary(l);
        await props.setSelectedTernary(r);

        let L = new Array(n1);
        let R = new Array(n2);

        await delay(props.time);
        for (let i = 0; i < n1; i++) {
            await setComparisons(++comparisons);
            await setArrayAccess(++arrayAccess+1);
            L[i] = array[l + i];
        }
        await setComparisons(++comparisons);

        await delay(props.time);
        for (let j = 0; j < n2; j++) {
            await setComparisons(++comparisons);
            await setArrayAccess(++arrayAccess+1);
            R[j] = array[m + 1 + j];
        }
        await setComparisons(++comparisons);

        let i = 0, j = 0;
        let k = l;

        await props.setSelectedMain(k);

        while (i < n1 && j < n2) {
            await delay(props.time);
            await setComparisons(++comparisons+1);
            await setArrayAccess(++arrayAccess+1);
            await setComparisons(++comparisons);
            if (L[i] <= R[j]) {
                await setArrayAccess(++arrayAccess+1);
                array[k] = L[i];
                i++;
            }
            else {
                await setArrayAccess(++arrayAccess+1);
                array[k] = R[j];
                j++;
            }
            k++;
            await props.setSelectedMain(k);
        }
        await setComparisons(++comparisons+1);

        while (i < n1) {
            await delay(props.time);
            await setComparisons(++comparisons);
            await setArrayAccess(++arrayAccess+1);
            array[k] = L[i];
            i++;
            k++;
            await props.setSelectedMain(k);
        }
        await setComparisons(++comparisons);

        while (j < n2) {
            await delay(props.time);
            await setComparisons(++comparisons);
            await setArrayAccess(++arrayAccess+1);
            array[k] = R[j];
            j++;
            k++;
            await props.setSelectedMain(k);
        }
        await setComparisons(++comparisons);
    }

    //Sweap function
    async function swap(items: any[], leftIndex: any, rightIndex: any) {
        await delay(props.time);
        await setArrayAccess(++arrayAccess);
        var temp = items[leftIndex];
        await delay(props.time);
        await setArrayAccess(++arrayAccess+1);
        items[leftIndex] = items[rightIndex];
        await delay(props.time);
        await setArrayAccess(++arrayAccess);
        items[rightIndex] = temp;
    }

    /////////////////////////////////////////////////////////
    //HeapSort Algorithm
    async function heapSort(arr: any[]) {
        let n = arr.length; 
  
        await setComparisons(++comparisons);
        for (let i = n / 2 - 1; i >= 0; i--){
            await setComparisons(++comparisons);
            await heapify(arr, n, i); 
        } 
  
        await setComparisons(++comparisons);
        for (let i=n-1; i>0; i--) 
        { 
            await delay(props.time);
            await setComparisons(++comparisons);
            await setArrayAccess(++arrayAccess);
            let temp = arr[0]; 
            await delay(props.time);
            await setArrayAccess(++arrayAccess+1);
            arr[0] = arr[i]; 
            await delay(props.time);
            await setArrayAccess(++arrayAccess);
            arr[i] = temp; 
            await props.setSelectedMain(i);
            await heapify(arr, i, 0); 
        } 
    }

   async function heapify(arr:any[], n:any, i:any) 
    { 
        let largest = i; 
        let l = 2*i + 1;
        let r = 2*i + 2; 

        await setComparisons(++comparisons+1);
        await setArrayAccess(++arrayAccess+1);
        if (l < n && arr[l] > arr[largest]) {
            await delay(props.time);
            largest = l;
            await props.setSelectedSecondary(l); 
        }
            
        await setComparisons(++comparisons+1);
        await setArrayAccess(++arrayAccess+1);
        if (r < n && arr[r] > arr[largest]){
            await delay(props.time);
            largest = r; 
            await props.setSelectedTernary(r);
        }
  
        await setComparisons(++comparisons);
        if (largest != i) 
        { 
            await delay(props.time);
            await setArrayAccess(++arrayAccess);
            let swap = arr[i]; 
            await setArrayAccess(++arrayAccess+1);
            arr[i] = arr[largest]; 
            await setArrayAccess(++arrayAccess);
            arr[largest] = swap; 
            await heapify(arr, n, largest); 
        } 
    }

    ////////////////////////////////////////////////
    //BubbleSort
    async function bubbleSort(m:any, len:any){
        for (let i = 0; i < len; i++) {
            await delay(props.time);
            await setComparisons(++comparisons);
            for (let j = 0; j < len - i - 1; j++) {
                await delay(props.time);
                await setComparisons(++comparisons);
                await props.setSelectedSecondary(j);
                await setComparisons(++comparisons);
                await setArrayAccess(++arrayAccess+1);
                if ( m[j] > m[j + 1]) {
                    await delay(props.time);
                    await setArrayAccess(++arrayAccess);
                    let tmp = m[j];
                    await setArrayAccess(++arrayAccess+1);
                    m[j] = m[j + 1];
                    await setArrayAccess(++arrayAccess);
                    m[j + 1] = tmp;
                }
            }
            await setComparisons(++comparisons);
        }
        await setComparisons(++comparisons);
    }
    ///////////////////////////////////////////////////
    //InsertionSort
    async function insertionSort(m:any, len:any){
        for (let i = 1; i < len; i++) {
            await delay(props.time);
            await setComparisons(++comparisons);
            await setArrayAccess(++arrayAccess);
            let key = m[i];
            await props.setSelectedMain(i);
            await delay(props.time);
            let j = i - 1;
            await setArrayAccess(++arrayAccess+1);
            while (j >= 0 && key < m[j]) {
                await delay(props.time);
                await setArrayAccess(++arrayAccess+1);
                await setComparisons(++comparisons+1);
                await props.setSelectedSecondary(j);
                await setArrayAccess(++arrayAccess+1);
                m[j + 1] = m[j];
                await delay(props.time);
                j -= 1;
            }
            await setComparisons(++comparisons+1);
            await delay(props.time);
            await setArrayAccess(++arrayAccess);
            m[j + 1] = key;
        }
        await setComparisons(++comparisons);

    }


    /////////////////////////////////////////////////////
    //SelectionSort
    async function selectionSort(m:any, len:any){
        for (let i = 0; i < len; i++) {
            let min = i;
            await delay(props.time);
            await setComparisons(++comparisons);
            await props.setSelectedMain(min);
            for (let j = i + 1; j < len; j++) {
                await delay(props.time);
                await setComparisons(++comparisons);
                await props.setSelectedSecondary(j);
                await setComparisons(++comparisons);
                await setArrayAccess(++arrayAccess+1);
                if (m[min] > m[j]) {
                    await delay(props.time);
                    min = j;
                    await props.setSelectedMain(min);
                }
            }
            await setComparisons(++comparisons);
            await delay(props.time);
            await setArrayAccess(++arrayAccess);
            let tmp = m[i];
            await delay(props.time);
            await setArrayAccess(++arrayAccess+1);
            m[i] = m[min];
            await delay(props.time);
            await setArrayAccess(++arrayAccess);
            m[min] = tmp;
        }
        await setComparisons(++comparisons);
    }

    async function sortValues() {
        let m = props.values;
        let len = m.length;
        await props.setSelectedMain(-1);
        await props.setSelectedSecondary(-1);
        await props.setSelectedTernary(-1);
        switch (props.algorithm) {
            case 'BUBBLE SORT':
                await bubbleSort(m,len);
                break;
            case 'INSERTION SORT':
                await insertionSort(m, len)
                break;
            case 'SELECTION SORT':
                await selectionSort(m, len);
                break;
            case 'QUICK SORT':
                await quicksort(m, 0, m.length - 1);
                break;
            case 'MERGE SORT':
                await mergeSort(m, 0, m.length - 1);
                break;
            case 'HEAP SORT':
                await heapSort(m);
                break;
        };

        await props.setValues(m);
    }

    return (
        <div className="selector-wrapper">
            <div className="selector-inputs">
                <div className="selector-component">
                    <input
                        type="number"
                        min={30}
                        max={700}
                        disabled={props.running}
                        step={10}
                        value={props.columns}
                        placeholder="NUM OF COLS"
                        onChange={updateCol}
                    >
                    </input>
                    <button>
                        <FormatListNumberedRtlIcon />
                    </button>
                </div>
                <div className="selector-component">
                    <input
                        type="number"
                        value={props.time}
                        min={5}
                        disabled={props.running}
                        max={200}
                        step={10}
                        placeholder="TIME IN MS"
                        onChange={updateTime}
                    >
                    </input>
                    <button>
                        <TimerIcon />
                    </button>
                </div>
            </div>
            <div className="selector-start">
                <button onClick={async () => {
                    await props.setSelectedMain(-1);
                    await props.setSelectedSecondary(-1);
                    await props.setSelectedTernary(-1);
                    if(!props.running){
                        await props.setRunning(true);
                        await forceUpdate(!force);
                        await sortValues();
                    }
                    await props.setRunning(false);
                    await props.setSelectedMain(-1);
                    await props.setSelectedSecondary(-1);
                    await props.setSelectedTernary(-1);
                }} disabled={props.running}>
                    {
                        (!props.running) ? <span>START</span> : <span>RUNNING</span>
                    }
                </button>
            </div>
        </div>
    )
}