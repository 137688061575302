import * as React from 'react';
import './Sidebar.scss';
import Tag from './Tag/Tag';
import Selector from './Selector/Selector';
import { Snackbar, Slide } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export default function Sidebar(props: any) {
    const algorithms = ['BUBBLE SORT', 'INSERTION SORT', 'SELECTION SORT', 'QUICK SORT', 'HEAP SORT', 'MERGE SORT']
    const [open, setOpen] = React.useState(false);
    const handleClose = (event:any) => {
        setOpen(false);
    };

    return (
        <div className="sidebar-wrapper">
            <div className="sidebar-title">
                <span>SORTING ALGORITHM VISUALIZER</span>
                <div className="sidebar-divider">
                </div>
            </div>
            <div className="sidebar-algorithms">
                {algorithms.map((item, i) => {
                    return (
                        <div key={i} onClick={() => {
                            if (props.running) {
                                setOpen(true);
                            } else {
                                props.changeAlgorithm(item);
                            }
                        }
                        }>
                            <Tag name={item} selected={item === props.algorithm} />
                        </div>
                    )
                })}
            </div>
            <div>
                <Selector time={props.time} running={props.running} setRunning={props.setRunning} comparisons={props.comparisons} setArrayAccess={props.setArrayAccess} arrayAccess={props.arrayAccess} setComparisons={props.setComparisons} setTime={props.setTime} columns={props.columns} setColumns={props.setColumns} values={props.values} setValues={props.setValues} setSelectedMain={props.setSelectedMain} setSelectedSecondary={props.setSelectedSecondary} setSelectedTernary={props.setSelectedTernary} algorithm={props.algorithm} />
            </div>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={open} autoHideDuration={3000} onClose={(e:any)=>handleClose(e)}>
                <MuiAlert onClose={(e:any)=>handleClose(e)} severity="error">
                    An <b>algorithm</b> is currently being <b>executed</b>. If you wish to abort, <b>reload</b> the page.
                </MuiAlert>
            </Snackbar>
        </div>
    )
}